@import "../../../styles/default.scss";

.cross_sell_unit {
    width: $default-crossSellUnit-width;
    display: flex;
    flex-direction: column;
    flex: 0 0 $default-crossSellUnit-width;
    font-size: $default-label-font-size;
    line-height: 16px;
    text-align: center;
    color: $dp-black;

    &:hover {
        color: $dp-black;
    }

    &:not(:last-of-type) {
        margin-right: 10px;
    }

    > * {
        width: 100%;
        user-select: none;
        letter-spacing: 0.3px;
        line-height: 19px;
    }

    .image {
        width: 100%;
        height: 135px;
        margin-bottom: 20px;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    .title {
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .price {
        font-weight: bold;
    }
}
