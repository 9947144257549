.scope-basket-modal {
    font-size: 13px;
    line-height: 16px;

    &,
    & *,
    & *::before,
    & *::after {
        box-sizing: border-box;
    }

    svg {
        overflow: visible;
        height: 100px;
        width: 100px;
    }

    .css-url {
        height: 300px;
    }

    .basket_modal_container {
        position: fixed;
        z-index: 1051;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }
}
