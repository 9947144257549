$breakpoint-mobile-extra-small: 320px;
$breakpoint-mobile-min-width: 768px;
$breakpoint-desktop-true-min-width: 769px;
$breakpoint-tablet-min-width: 1024px;
$breakpoint-desktop-min-width: 1200px;

$dp-red: #e30613;
$dp-light-red: #ff0818;
$dp-green: #70a848;
$dp-orange: orange;
$dp-black: #000000;
$dp-dark-gray: #1e1e1e;
$dp-white: white;
$dp-Gainsboro: #e6e6e6;
$dp-boxshadow: #d6d6f5;
$dp-dropwodn-boxshadow: rgba(0, 0, 0, 0.2);
$dp-light-grey: #d4d4d4;
$dp-light-grey_v2: #F0F1F1;
$dp-grey: #d6d6d6;
$dp-mid-grey: #d3d4d4;
$dp-dark-grey: #999999;

$dp-grey-inactive: #bbbbbb;

$default-spacing: 8px;
$default-letter-spacing: 0.3px;
$default-line-height: 19px;
$default-title-font-size: 15px;
$default-label-font-size: 13px;
$default-button-height: 48px;

$default-crossSellUnit-width: 150px;

// exports js variables
:export {
    breakpointMobileExtraSmall: $breakpoint-mobile-extra-small;
    breakpointMobileMinWidth: $breakpoint-mobile-min-width;
    breakpointTabletMinWidth: $breakpoint-tablet-min-width;
    breakpointDesktopMinWidth: $breakpoint-desktop-min-width;
    dpRed: $dp-red;
    dpGreen: $dp-green;
    dpOrange: $dp-orange;
    dpBlack: $dp-black;
    dpDarkGray: $dp-dark-gray;
    dpWhite: $dp-white;
    dpGainsboro: $dp-Gainsboro;
    dpBoxshadow: $dp-boxshadow;
    dpDropwodnBoxshadow: $dp-dropwodn-boxshadow;
    dpLightGrey: $dp-light-grey;
    dpGrey: $dp-grey;
    dpDarkGrey: $dp-dark-grey;
    dpGreyInactive: $dp-grey-inactive;
    defaultSpacing: $default-spacing;
    defaultCrossSellUnitWidth: $default-crossSellUnit-width;
}
