.scope-basket-modal {
    .has-focus-keyboard {
        outline: -webkit-focus-ring-color auto 1px;
        outline: Highlight solid 1px; // Firefox
    }

    &.disable_tabNav_styles {
        .has-focus,
        .has-focus-mouse {
            outline:0 !important;
        }

        div[focusable],
        button[focusable],
        a[focusable] {
            &:focus {
                outline:0 !important;                
            }
        }

        .bm-cross_sell_unit {
            &:focus {
                color: black !important;
            }
        }
    }
}
