@import "../styles/default.scss";

:global {
    body.bm_scroll_lock {
        overflow: hidden !important;
        position: fixed !important;

        &.parallax, &.parallax2 {
            position: static !important;
        }
    }
}

.modal_wrapper {
    position: fixed;
    z-index: 1051;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    cursor: default;

    .modal_container {
        display: flex;
        height: 100%;
        justify-content: center;
        align-items: flex-start;
        letter-spacing: $default-letter-spacing;
        line-height: $default-line-height;

        .modal_content {
            background-color: $dp-white;
            padding: 20px 40px;
            border: solid $dp-light-grey 1px;
            margin: auto;
            display: flex;
            flex-direction: column;

            .modal_header {
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-bottom: solid $dp-mid-grey 1px;
                padding: 0 0 ($default-spacing * 2) 0;

                .modal_title {
                    font-weight: bold;
                    font-size: $default-title-font-size;
                    padding-right: 50px;
                    width: calc(100% - 50px);
                    text-transform: uppercase;
                    box-sizing: border-box;
                    color: $dp-black;
                }

                .close_button {
                    align-self: flex-start;
                    height: $default-button-height;
                    width: 48px;
                    border: solid $dp-light-grey 1px;
                    transition: border-color 300ms;
                    border-radius: 50px;
                    background-color: $dp-white;
                    cursor: pointer;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    svg {
                        fill: $dp-black;
                    }

                    &:hover {
                        transition: border-color 300ms;
                        border-color: $dp-black;
                    }
                }
            }

            .inner_content {
                flex-shrink: 1;
                overflow-y: scroll;
                overflow-x: hidden;
                -ms-overflow-style: none;
                scrollbar-width: none;

                &::-webkit-scrollbar {
                    display: none;
                }

                .modal_body {
                    display: flex;
                    padding: 30px 0px;
                    font-size: $default-label-font-size;
                    color: $dp-black;

                    .main_image_container {
                        padding-right: 15px;
                        width: calc(100% - 200px);

                        .main_image {
                            img {
                                max-width: 100%;
                                max-height: 250px;
                            }
                        }
                    }

                    .details_container {
                        margin: auto;
                        width: 200px;

                        > div {
                            padding: 10px 0 0 0;
                            font-size: $default-label-font-size;
                        }

                        .article_name,
                        .description {
                            font-weight: bold;
                            color: $dp-black;
                        }

                        .color,
                        .size,
                        .model,
                        .quantity {
                            text-transform: capitalize;
                            color: $dp-dark-grey;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            overflow: hidden;
                        }
                    }

                    .self_service_body {
                        display: block;
                    }
                }
            }
        }

        .modal_footer {
            border-top: solid $dp-mid-grey 1px;
            padding: ($default-spacing * 2) 0 0 0;

            &.self_service_no_border {
                border: none;
            }

            .button_container {
                display: flex;
                cursor: pointer;
                font-weight: 500;
                text-align: center;
                align-items: center;
                justify-content: center;
                height: $default-button-height;
                padding: 0 30px;
                border-radius: 50px;
                border: 1px solid $dp-mid-grey;
                transition: border-color 300ms;
                float: left;
                color: $dp-black;

                &.red {
                    float: right;
                    border: none;
                    transition: background-color 300ms;
                    color: white;
                    min-width: 100px;
                    background-color: $dp-red;

                    &:hover {
                        transition: background-color 300ms;
                        background-color: $dp-light-red;
                    }
                }

                &.grey {
                    transition: background-color 300ms;
                    background-color: $dp-light-grey_v2;
                    border: none;

                    &:hover {
                        transition: background-color 300ms;
                        background-color: $dp-mid-grey;
                    }
                }

                .button_icon {
                    padding-right: 10px;

                    svg {
                        margin: 0px 4px -5px 0px;
                        fill: $dp-black;
                    }
                }

                .button_label {
                    font-size: $default-label-font-size;
                }

                &:hover {
                    transition: border-color 300ms;
                    border-color: $dp-black;
                }
            }
        }
    }

    @media only screen and (min-width: $breakpoint-mobile-extra-small) {
        .modal_container {
            .modal_content {
                width: 100%;
                max-height: 100%;
            }

            .modal_footer {
                display: flex;
                flex-direction: column;

                .button_container:first-child {
                    margin-bottom: $default-spacing;
                }
            }
        }
    }

    @media only screen and (min-width: $breakpoint-desktop-true-min-width) {
        .modal_container {
            .modal_content {
                width: 600px;
                height: auto;
                max-height: calc(100vh - 140px);
            }

            .modal_footer {
                display: block;

                .button_container:first-child {
                    margin-bottom: initial;
                }
            }
        }
    }

    @media only screen and (min-width: $breakpoint-desktop-min-width) {
        .modal_container {
            align-items: center;
        }
    }

    @media only screen and (max-width: $breakpoint-mobile-min-width) {
        .modal_container {
            .modal_content {
                box-sizing: border-box;
                padding: 20px 16px;
                max-height: 100%;
            }
        }
    }
}
