@import "../../../styles/default.scss";

.cross_sell {
    width: 100%;
    padding: 20px 0;
    border-top: 1px solid $dp-mid-grey;

    .cross_sell_header {
        width: 100%;
        line-height: 14px;
        font-size: $default-label-font-size;
        font-weight: bold;
        margin-bottom: 14px;
        user-select: none;
        color: $dp-black;

        .cross_sell_title {
            display: inline-block;
        }

        .cross_sell_pager {
            float: right;
        }
    }

    .slider_wrapper {
        display: flex;
        flex-direction: row;
        width: calc(100% + 30px);
        position: relative;
        left: -15px;

        .right_arrow, .left_arrow {
            display: flex;
            width: 34px;
            position: relative;
            cursor: pointer;
            flex: 1 0 34px;

            svg {
                width: 13px;
                margin: auto;
            }

            &.hidden {
                visibility: hidden;
            }
        }

        .left_arrow {
            right: -10px;

            svg {
                margin-right: 0;
            }

            &::after {
                content: "";
                height: 100%;
                width: 10px;
                background-image: linear-gradient(to right, $dp-white, rgba(255, 255, 255, 0));
                position: relative;
                left: -2px;
            }
        }


        .right_arrow {
            left: -10px;

            svg {
                margin-left: 0;
            }

            &::before {
                content: "";
                height: 100%;
                width: 10px;
                background-image: linear-gradient(to left, $dp-white, rgba(255, 255, 255, 0));
                position: relative;
                right: -2px;
            }
        }

        .slider {
            width: 480px;
        }

        .cross_sell_content {
            display: flex;
            flex-direction: row;
            width: max-content;
        }
    }
}
